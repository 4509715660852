/**
 * 404 page
 */

import React from 'react';
import get from 'lodash/get';
import { useStaticQuery, graphql } from 'gatsby';
import { PageLayout } from '@layout';

const NotFoundPage = () => {
  const options = get(useStaticQuery(graphql`
    query PageNotFoundOptions {
      wordpressAcfOptions {
        options {
          wordpress_404_title_primary
          wordpress_404_title_secondary
          wordpress_404_description
        }
      }
    }
  `),
  'wordpressAcfOptions.options', {});

  return (
    <PageLayout 
      siteMeta={{ title: '404 - page not found' }}
      pageHeader={{
        primary: options.wordpress_404_title_primary,
        secondary: options.wordpress_404_title_secondary,
        intro: options.wordpress_404_description,
      }}
    />
  )
}

export default NotFoundPage;
